import * as React from "react";
import {FC, useEffect, useState} from "react";
import {extraForm, formButton, formHeading, formInput, formLabel, loginBanner, loginImg, errorMsg} from './index.module.scss';
import Layout from "../../components/Navigation/layout";
import {Button, Col, Form, Row} from "react-bootstrap";
import {Link, navigate} from "gatsby";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import "../../theme/main.module.scss";
import {Client, handleApiError} from "../../services/ApiService";
import {connect, ConnectedProps} from "react-redux";
import {StaticImage} from "gatsby-plugin-image";
import {setKey} from '../../stores/auth/authSlice';
import {setBasketAmount, setBasketID} from '../../stores/basket/basketSlice';
import {setMember, setMemberID, setVendorID} from '../../stores/member/memberSlice';
import {toast} from "react-toastify";
import Loading from "../../components/Loading/Loading";

const mapState = ({auth, basket}) => ({
    auth,
    basket,
})

const mapDispatch = {
    setKey,
    setBasketID,
    setMember,
    setMemberID,
    setVendorID,
    setBasketAmount,
}

const connector = connect(mapState, mapDispatch);

type PropsFromRedux = ConnectedProps<typeof connector>

interface FormInputs {
    Username: string,
    Password: string,
}

const schema = yup.object().shape({
    Username: yup.string().required('Bitte gib einen Benutzernamen an.'),
    Password: yup.string().required('Bitte gib ein Passwort ein.')
})

const schemaPW = yup.object().shape({
    Username: yup.string().required('Bitte gib einen Benutzernamen an.'),
})

const IndexPage: ({
                      setKey,
                      setBasketID,
                      setMemberID,
                      setMember,
                      setVendorID,
                      basket,
                      setBasketAmount
                  }: { setKey: any; setBasketID: any; setMemberID: any; setMember: any; setVendorID: any; basket: any; setBasketAmount: any }) => JSX.Element = ({
                                           setKey,
                                           setBasketID,
                                           setMemberID,
                                           setMember,
                                           setVendorID,
                                           basket,
                                           setBasketAmount
                                       }) => {
    const {register, handleSubmit, formState: {errors}} = useForm<FormInputs>({
        resolver: yupResolver(schema)
    });

    const {register: registerPW, handleSubmit: handleSubmitPW, formState: {errors: errorsPW}} = useForm<FormInputs>({
        resolver: yupResolver(schemaPW)
    });

    const [forgotPW, setForgotPW] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    function onSubmit(userData: FormInputs) {
        setLoading(true);
        Client.Raw.post('Member/authorize', {
            Email: userData.Username,
            Password: userData.Password
        }).then((resAuth) => {
            Client.Raw
                .get('Member/' + resAuth.data.MemberID)
                .then((res) => {
                    setVendorID(res.data.VendorID);
                    setMember(res.data);
                    Client.Basket
                        .fetchAuthorized(resAuth.data.Key, basket.BasketID ? basket.BasketID : '')
                        .then((res) => {
                            setBasketID(res.data.ID);
                            setBasketAmount(res.data.TotalItems);
                            navigate('/user');
                        }).catch(handleApiError);
                }).catch(handleApiError)
            toast.success('Erfolgreich eingeloggt!');
            setKey(resAuth.data.Key);
            setMemberID(resAuth.data.MemberID);
        }).catch((error) => {
            handleApiError(error)
            setLoading(false);
            // toast.error('Benutzername oder Passwort falsch eingegeben.');
            setError('Benutzername oder Passwort falsch eingegeben.')
        });
    }

    function submitPW(userData: FormInputs) {
        Client.Raw.get('Member/sendResetEmail?email=' + userData.Username).then((res) => {
            toast.success('E-Mail verschickt!', res.data.message);
            setForgotPW(false);
        }).catch(handleApiError)
    }


    return (
        <Layout pageTitle="Login">
            <div className={loginBanner} >
                {loading ? <Loading variant={"light"} type={"border"} message={"Daten werden verarbeitet"}/>
                    : <div className="container h-100 d-flex flex-column">
                        <Row className="justify-content-center align-items-center flex-grow-1">
                            <Col lg={8} offset={2}>
                                {!forgotPW ?
                                    <>
                                        <h2 className={formHeading}> Servus und Hallo! </h2>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Form.Group controlId="formBasicEmail" className="mb-2">
                                                <Form.Label className={formLabel}>Benutzer</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className={formInput}
                                                    {...register('Username', {
                                                        onChange: () => setError('')
                                                    })}
                                                />
                                                <Form.Text className={errorMsg}>
                                                    {errors.Username?.message}
                                                </Form.Text>
                                            </Form.Group>
                                            <Form.Group controlId="formBasicPassword">
                                                <Form.Label className={formLabel}>Passwort</Form.Label>
                                                <Form.Control
                                                    type="password"
                                                    className={formInput}
                                                    {...register('Password', {
                                                        onChange: () => setError('')
                                                    })}
                                                />
                                                <Form.Text className={errorMsg}>
                                                    {errors.Password?.message}
                                                    <div>{error}</div>
                                                </Form.Text>
                                            </Form.Group>
                                            <Button className={formButton} type="submit">
                                                Login
                                            </Button>
                                        </Form>

                                        <div className={extraForm}>
                                            <Col lg={8} md={8} xs={8}>
                                                Noch kein Account? <Link to="/register"> Zur Registrierung </Link>
                                            </Col>
                                            <Col lg={4} md={4} xs={4} className="d-flex justify-content-end">
                                                <a onClick={() => setForgotPW(!forgotPW)}>Passwort vergessen?</a>
                                            </Col>
                                        </div>
                                    </> :
                                    <>
                                        <h2 className={formHeading}> {forgotPW ? 'Passwort vergessen?' : 'Servus und Hallo! '} </h2>
                                        <Form onSubmit={handleSubmitPW(submitPW)}>
                                            <Form.Group controlId="formBasicEmail" className="mb-2">
                                                <Form.Label className={formLabel}>E-Mail-Adresse</Form.Label>
                                                <Form.Control type="text"
                                                              className={formInput} {...registerPW('Username')} />
                                                <Form.Text className={errorMsg}>
                                                    {errorsPW.Username?.message}
                                                </Form.Text>
                                            </Form.Group>
                                            <Button className={formButton} type="submit">
                                                Passwort zurücksetzen
                                            </Button>
                                        </Form>
                                        <div className={extraForm}>
                                            <Col lg={4} className="d-flex">
                                                <a onClick={() => setForgotPW(!forgotPW)}>Zum Login</a>
                                            </Col>
                                        </div>
                                    </>
                                }
                            </Col>
                        </Row>
                    </div>
                }

            </div>
        </Layout>
    )
}

export default connector(IndexPage);
